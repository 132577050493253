import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, InputNumber, Select, message, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import axios from 'axios';
import messages from './messages';
import {
  STATUS_TYPE_ARRANGED,
  STATUS_TYPE_CANCELED,
  STATUS_TYPE_DELIVERED,
  STATUS_TYPE_FOLLOWUP,
  STATUS_TYPE_NOTDELIVERED,
  STATUS_TYPE_PREPARATION,
  STATUS_TYPE_RECEIVED,
  STATUS_TYPE_SHIPPED,
  RETURN_REASON_NONE,
  RETURN_REASON_LATEDELIVERY,
  RETURN_REASON_DONTLIKEANYMORE,
  RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING,
  RETURN_REASON_ITEMBROKENORDAMAGED,
  RETURN_REASON_MISPICKWRONGITEMDELIVERED,
  RETURN_REASON_FOUNDCHEAPERPRICE,
  RETURN_REASON_SHIPPINGPACKAGINGDAMAGED,
  RETURN_REASON_WRONGDESCRIPTION,
  RETURN_REASON_ITEMTOOBIGORSMALL,
} from './constants';

const getStatusTypes = (intl) => [
  {
    label: intl.formatMessage(messages.statusReceived),
    value: STATUS_TYPE_RECEIVED,
  },
  {
    label: intl.formatMessage(messages.statusPreparation),
    value: STATUS_TYPE_PREPARATION,
  },
  {
    label: intl.formatMessage(messages.statusShipped),
    value: STATUS_TYPE_SHIPPED,
  },
  {
    label: intl.formatMessage(messages.statusDelivered),
    value: STATUS_TYPE_DELIVERED,
  },
  {
    label: intl.formatMessage(messages.statusCanceled),
    value: STATUS_TYPE_CANCELED,
  },
  {
    label: intl.formatMessage(messages.statusNotDelivered),
    value: STATUS_TYPE_NOTDELIVERED,
  },
  {
    label: intl.formatMessage(messages.statusFollowUp),
    value: STATUS_TYPE_FOLLOWUP,
  },
  {
    label: intl.formatMessage(messages.statusArranged),
    value: STATUS_TYPE_ARRANGED,
  },
];

const getReturnReasons = (intl) => [
  {
    label: intl.formatMessage(messages.returnReason_None),
    value: RETURN_REASON_NONE,
  },
  {
    label: intl.formatMessage(messages.returnReason_LateDelivery),
    value: RETURN_REASON_LATEDELIVERY,
  },
  {
    label: intl.formatMessage(messages.returnReason_DontLikeAnymore),
    value: RETURN_REASON_DONTLIKEANYMORE,
  },
  {
    label: intl.formatMessage(messages.returnReason_MissingPartsOrAccessories),
    value: RETURN_REASON_MISSINGPARTSORACCESSORIESMISSING,
  },
  {
    label: intl.formatMessage(messages.returnReason_ItemBrokenOrDamaged),
    value: RETURN_REASON_ITEMBROKENORDAMAGED,
  },
  {
    label: intl.formatMessage(messages.returnReason_WrongItemDelivered),
    value: RETURN_REASON_MISPICKWRONGITEMDELIVERED,
  },
  {
    label: intl.formatMessage(messages.returnReason_FoundCheaperPrice),
    value: RETURN_REASON_FOUNDCHEAPERPRICE,
  },
  {
    label: intl.formatMessage(messages.returnReason_ShippingPackageDamaged),
    value: RETURN_REASON_SHIPPINGPACKAGINGDAMAGED,
  },
  {
    label: intl.formatMessage(messages.returnReason_WrongDescription),
    value: RETURN_REASON_WRONGDESCRIPTION,
  },
  {
    label: intl.formatMessage(messages.returnReason_ItemTooBigOrSmall),
    value: RETURN_REASON_ITEMTOOBIGORSMALL,
  },
];

const getColumns = (intl) => [
  {
    title: intl.formatMessage(messages.positionNumber),
    dataIndex: 'positionNumber',
    key: 'positionNumber',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.itemNumber),
    dataIndex: 'itemNumber',
    key: 'itemNumber',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.itemName),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: intl.formatMessage(messages.quantity),
    dataIndex: 'totalQuantity',
    key: 'totalQuantity',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: 'positionStatus',
    width: '8%',
    render: (text) =>
      getStatusTypes(intl).find((element) => element.value === text)?.label,
  },
];

const getEditColumns = (intl, handleUpdate) => [
  {
    title: intl.formatMessage(messages.positionNumber),
    dataIndex: 'positionNumber',
    key: 'positionNumber',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.itemNumber),
    dataIndex: 'itemNumber',
    key: 'itemNumber',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.itemName),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: intl.formatMessage(messages.returnableQuantity),
    dataIndex: 'totalQuantity',
    key: 'totalQuantity',
    width: '8%',
  },
  {
    title: intl.formatMessage(messages.askedQuantity),
    dataIndex: 'askedQuantity',
    key: 'askedQuantity',
    width: '8%',
    render: (_, record) => {
      const isDelivered = record.positionStatus === STATUS_TYPE_DELIVERED;
      return (
        <InputNumber
          type="number"
          maxLength={4}
          disabled={!isDelivered}
          onChange={(value) => {
            handleUpdate(record, 'askedQuantity', value);
          }}
        />
      );
    },
  },
  {
    title: intl.formatMessage(messages.returnReason),
    dataIndex: 'returnReason',
    key: 'returnReason',
    width: '20%',
    render: (_, record) => {
      const isNotDelivered = record.positionStatus !== STATUS_TYPE_DELIVERED;
      return (
        <Select
          allowClear
          style={{ width: '100%' }}
          options={getReturnReasons(intl)}
          disabled={isNotDelivered}
          onChange={(value) => handleUpdate(record, 'returnReason', value)}
        />
      );
    },
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: 'positionStatus',
    width: '8%',
    render: (text) =>
      getStatusTypes(intl).find((element) => element.value === text)?.label,
  },
];

const ItemsTable = ({ data, dataProcessed, setDataProcessed }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { orderNumber, positions } = data;
  const [retPositions, setRetPositions] = useState(positions);
  const [isDataValid, setIsDataValid] = useState(false);

  const handleUpdate = (record, field, value) => {
    const updatedData = retPositions.map((row) => {
      if (row.positionNumber === record.positionNumber) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRetPositions(updatedData);
  };

  useEffect(() => {
    const allRowsValid = retPositions.map((retPosition) => {
      if (
        (retPosition.positionStatus === STATUS_TYPE_DELIVERED &&
          retPosition.askedQuantity !== null &&
          retPosition.askedQuantity !== undefined) ||
        (retPosition.returnReason !== null &&
          retPosition.returnReason !== undefined)
      ) {
        return (
          retPosition.askedQuantity !== null &&
          retPosition.askedQuantity !== undefined &&
          retPosition.askedQuantity > 0 &&
          retPosition.askedQuantity <= retPosition.totalQuantity &&
          retPosition.returnReason !== null &&
          retPosition.returnReason !== undefined
        );
      }
      return null;
    });
    setIsDataValid(
      allRowsValid?.includes(true) && !allRowsValid?.includes(false),
    );
  }, [retPositions]);

  const saveData = async () => {
    try {
      const toPostReturnPositions = retPositions.filter(
        (retPosition) =>
          retPosition.positionStatus === STATUS_TYPE_DELIVERED &&
          retPosition.askedQuantity > 0 &&
          retPosition.returnReason,
      );

      const returnObject = {
        orderNumber: orderNumber,
        returnPositions: toPostReturnPositions,
      };

      var response = await axios.post(
        `/api/home-delivery/returns/create-label-by-customer`,
        returnObject,
      );

      if (response.status === 200) {
        setDataProcessed(false);
        setIsDataValid(false);

        message.success(intl.formatMessage(messages.apiCreateReturnSuccess));

        const { data } = response;
        const { orderNumber, customerPostcode } = data;

        const refreshObject = {
          orderNumber,
          Postcode: customerPostcode,
        };

        var response2 = await axios.post(
          `/api/home-delivery/orders/is-returnable-by-user-request`,
          refreshObject,
        );

        if (response2.status === 200) {
          navigate('/return', {
            state: { order: response2.data },
          });
        }
      }
    } catch (error) {
      const { Errors } = JSON.parse(error.request.response);
      if (Errors) {
        Errors.map((e) => message.error(e));
      } else {
        message.error(error.message);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <div style={{ marginBottom: 20, textAlign: 'left' }}>
        <Button
          type={dataProcessed ? '' : 'primary'}
          style={{
            background: dataProcessed && 'lightgray',
            width: 150,
          }}
          onClick={() => {
            setDataProcessed(!dataProcessed);
          }}
        >
          {dataProcessed
            ? intl.formatMessage(messages.returnFormCancel)
            : intl.formatMessage(messages.returnFormCreateReturn)}
        </Button>
      </div>
      <Table
        columns={
          dataProcessed ? getEditColumns(intl, handleUpdate) : getColumns(intl)
        }
        dataSource={positions}
        pagination={false}
        bordered
        size="small"
        rowKey="positionNumber"
      />
      {dataProcessed && (
        <>
          <div
            style={{
              marginTop: 5,
              textAlign: 'center',
              visibility: isDataValid && 'hidden',
            }}
          >
            <Typography.Text type="danger">
              {intl.formatMessage(messages.returnFormValidationMessage)}
            </Typography.Text>
          </div>
          <div style={{ marginTop: 5, textAlign: 'center' }}>
            <Button
              type="primary"
              style={{
                background: isDataValid && 'green',
                marginTop: 10,
                width: 150,
              }}
              onClick={saveData}
              disabled={!isDataValid}
            >
              {intl.formatMessage(messages.returnFormSave)}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

ItemsTable.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  dataProcessed: PropTypes.bool,
  setDataProcessed: PropTypes.func,
};

export default ItemsTable;
